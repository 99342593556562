// Here you can add other styles
.btn-noevent-icon svg {
  pointer-events: none;
}

.modal-tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-inline-start: 0;
  margin: 8px 0 0;
  list-style: none;
}

.modal-search-wrapper {
  position: relative;
  display: block;
  min-height: 180px;
}

.modal-tag-list li {
  display: block;
  padding: 2px 4px;
  background-color: #fff;
  border: solid 2px #5856d6;
  border-radius: 4px;
  cursor: pointer;

  [data-coreui-theme=dark] & {
    background-color: var(--cui-secondary-bg);
    border-color: #212233;
  }
}

.tr-w-120 {
  width: 120px;
}

.table {
  .td-action {
    width: 98px;
    padding: 0.2rem;
  }

  .td-action-three {
    width: 144px;
    padding: 0.2rem;
  }
}

.modal-search-list {
  position: absolute;
  top: 50px;
  left: .5rem;
  z-index: 11;
  display: none;
  width: calc(100% - 1rem);
  max-height: 160px;
  padding: 8px 0;
  overflow-y: auto;
  background-color: #cecece;
  border-radius: 4px;

  [data-coreui-theme=dark] & {
    background-color: var(--cui-secondary-bg);
  }
}

.modal-search-list li {
  padding: 0 8px;
  cursor: pointer;
  transition: .4s;
}

.modal-search-list li:hover {
  background-color: var(--cui-gray-500);
}

.modal-search-list li[data-tag-status="chosen"] {
  color: var(--cui-green);
}
.modal-search-list li[data-item-status="chosen"] {
  color: var(--cui-green);
}


.docs-wrapper {
  width: 100%;

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.docs-link {
  display: flex;
  gap: 0.75rem;
  padding: 12px 16px;
  border-radius: 4px;

  svg {
    color: rgba(255, 255, 255, 0.38);
  }
}

.sidebar-header {
  padding: 8px;
  transition: .15s;

  .docs-link {
    min-height: calc(2rem + 1px);
  }

  .docs-link:hover {
    color: #fff;
    text-decoration: none;
    background: #2a303d;

    svg {
      color: #fff;
    }
  }
}

[data-progress-status="wait"] span {
  background-color: var(--cui-warning);
  padding: 4px 8px;
  border-radius: 8px;
}

[data-progress-status="in-progress"] span {
  background-color: var(--cui-info);
  padding: 4px 8px;
  border-radius: 8px;
}

[data-progress-status="done"] span {
  background-color: var(--cui-success);
  padding: 4px 8px;
  border-radius: 8px;
}

.page-link {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-confirm {
  display: flex;
  justify-content: center;
  padding: 0 6px;
  // gap: 2px;

  button {
    width: 40%;
  }
}

.table-responsive-custom {
  margin-bottom: 0;
  margin-top: 50px;

  td {
    text-wrap: nowrap;
  }

  .td-action-three {
    width: unset;
  }
}

.table-responsive-custom_overflow {
  margin-top: -50px;
}

.table-cell_responsive {
  position: relative;
  overflow: hidden;
  max-width: 300px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1));

    [data-coreui-theme="light"] & {
      background: linear-gradient(0.25turn, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1));

    }

    [data-coreui-theme="dark"] & {
      background: linear-gradient(0.25turn, rgba(33, 38, 49, 0), rgba(33, 38, 49, 1) 80%, rgba(33, 38, 49, 1));
    }
  }
}

.table-hover > tbody > tr:hover > .table-cell_responsive:before {
  [data-coreui-theme="light"] & {
    background: linear-gradient(0.25turn, rgba(#080a0c13, 0), rgba(235, 235, 235, 1) 80%, rgba(235, 235, 235, 1));
  }

  [data-coreui-theme="dark"] & {
    background: linear-gradient(0.25turn, rgba(50, 54, 64, 0), rgba(50, 54, 64, 1) 80%, rgba(50, 54, 64, 1));
  }
}

.custom-tooltip {
  --cui-tooltip-max-width: 600px;
}
